import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import NextIcon from "@material-ui/icons/NavigateNext";
import PreviousIcon from "@material-ui/icons/NavigateBefore";
import { JsonLd } from "./JsonLd";
import { navigate } from "gatsby";

const FullElement = styled.li`
  font-family: ${(props) => props.theme.font.secondary};
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-width: 0;
  &:last-child {
    flex: 1;
  }
`;

const LastButton = styled(Button)`
  font-family: ${(props) => props.theme.font.secondary};
  color: #666;
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline;
    text-align: left;
    width: 100%;
  }
`;

const NonLastButton = styled(Button)`
  font-family: ${(props) => props.theme.font.secondary};
`;

const Full = styled.ol`
  font-family: ${(props) => props.theme.font.secondary};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  margin-top: 0.5rem;
  padding: 0;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Mini = styled.div`
  font-family: ${(props) => props.theme.font.secondary};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  padding: 0;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const PrevIcon = styled(PreviousIcon)`
  margin-right: 5px;
  margin-top: -10px;
  margin-bottom: -10px;
`;

export class Breadcrumbs extends React.Component {
  render() {
    const { path, miniElement } = this.props;
    return (
      <>
        <Full>
          {/* <li>
            <Home
              disableTouchRipple
              focusRipple
              size={"small"}
              href={"/"}
              aria-label="Strona główna"
            >
              <HomeIcon />
            </Home>
          </li> */}
          {path.map((element, i) => (
            <FullElement key={element.href}>
              {i > 0 ? <NextIcon /> : null}
              {i + 1 >= path.length ? (
                <LastButton
                  size={"small"}
                  disabled={element.disabled}
                  href={
                    i + 1 >= path.length || element.disabled || element.current
                      ? null
                      : element.href
                  }
                  style={element.current ? { pointerEvents: "none" } : {}}
                  aria-label={element.label}
                  onClick={(ev) => {
                    ev.preventDefault();
                    navigate(element.href, {
                      state: element.state,
                    });
                  }}
                >
                  {element.label}
                </LastButton>
              ) : (
                <NonLastButton
                  size={"small"}
                  disabled={element.disabled}
                  href={
                    i + 1 >= path.length || element.disabled || element.current
                      ? null
                      : element.href
                  }
                  style={element.current ? { pointerEvents: "none" } : {}}
                  aria-label={element.label}
                  onClick={(ev) => {
                    ev.preventDefault();
                    navigate(element.href, {
                      state: element.state,
                    });
                  }}
                >
                  {element.label}
                </NonLastButton>
              )}
            </FullElement>
          ))}
        </Full>
        {miniElement ? (
          <Mini>
            <NonLastButton
              size={"small"}
              href={miniElement.href}
              aria-label={miniElement.label}
              onClick={(ev) => {
                ev.preventDefault();
                navigate(miniElement.href, {
                  state: miniElement.state,
                });
              }}
            >
              <PrevIcon />
              {miniElement.label}
            </NonLastButton>
          </Mini>
        ) : null}
        <JsonLd
          data={{
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              // {
              //   "@type": "ListItem",
              //   position: 1,
              //   item: {
              //     "@id": "https://www.najtansza-wypozyczalnia-samochodow.pl",
              //     name: "Strona główna"
              //   }
              // },
              ...path.map((element, i) => ({
                "@type": "ListItem",
                position: i + 1,
                item: {
                  "@id":
                    "https://www.najtansza-wypozyczalnia-samochodow.pl" +
                    element.href,
                  name: element.label,
                },
              })),
            ],
          }}
        />
      </>
    );
  }
}
