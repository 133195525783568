import styled from "styled-components";
import Img from "gatsby-image";

export const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

export const Segment = styled.div``;
export const SegmentHeader = styled.h2`
  font-family: ${(props) => props.theme.font.primary};
  ${(props) => props.theme.font_size.large};
  margin: 1rem 0;
`;
export const SegmentCars = styled.div`
  display: grid;
  grid-gap: 32px;

  @media (min-width: 1080px) {
    grid-auto-flow: column;
    grid-auto-columns: 500px;
  }
`;
