import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import CarCard from "@common/CarCard";
import { Wrapper, Segment, SegmentHeader, SegmentCars } from "./style";

const Cars = ({ onChoose, rentalState }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            segments {
              name
              dayPrices
              deposit
              insurance
              cars {
                id
                name
                fuelType
                transmission
                engineEfficiency
                airConditioning
                fuelTypeFull
                seats
                doors
                productionYear
                engineVolume
                enginePower
                trunkCapacity
                range
                equipment
                description
              }
            }
          }
        }
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `
  );

  const segments = data.site.siteMetadata.segments;
  return (
    <Wrapper>
      {segments.map((segment) => {
        return (
          <Segment key={segment.name}>
            <SegmentHeader
              id={encodeURIComponent(`segment-${segment.name.toLowerCase()}`)}
            >
              Segment {segment.name}
            </SegmentHeader>
            <SegmentCars>
              {segment.cars.map((car) => {
                const foundNode = data.allImageSharp.edges.find((element) => {
                  // Match string after final slash
                  return element.node.fluid.src
                    .split("/")
                    .pop()
                    .includes(car.id);
                });
                const img = foundNode ? foundNode.node.fluid : null;
                return (
                  <CarCard
                    key={car.id}
                    segment={segment}
                    car={car}
                    img={img}
                    rentalState={rentalState}
                    onChoose={onChoose.bind(this, car, segment)}
                  />
                );
              })}
            </SegmentCars>
          </Segment>
        );
      })}
    </Wrapper>
  );
};
export default Cars;
