import React, { useEffect } from "react";
import styled from "styled-components";

import Layout from "@common/Layout";
import { TopHeader } from "@common/TopHeader";
import Cars from "@common/Cars";
import { navigate } from "gatsby";

import { Breadcrumbs } from "@common/Breadcrumbs";

import Footer from "@sections/Footer";
import { Container } from "@components/global";

export const Header = styled.h1`
  font-family: ${(props) => props.theme.font.primary};
  ${(props) => props.theme.font_size.larger};
  margin: 1rem 0;
`;

const ReservationCarPage = ({ location }) => {
  useEffect(() => {
    if (typeof window !== "undefined" && !location.state) {
      window.location.href = "/";
    }
  });
  if (typeof window !== "undefined" && !location.state) {
    return null;
  }
  return (
    <Layout>
      <TopHeader />
      <Container>
        <Breadcrumbs
          path={[
            {
              href: `/`,
              label: "Rezerwacja",
              state: location.state,
            },
            {
              href: `/rezerwacja/samochod`,
              label: "Wybór samochodu",
              state: location.state,
              current: true,
            },
            {
              href: `/rezerwacja/ubezpieczenie`,
              label: "Ubezpieczenie",
              state: location.state,
              disabled: true,
            },
            {
              href: `/rezerwacja/udogodnienia`,
              label: "Udogodnienia",
              state: location.state,
              disabled: true,
            },
            ,
            {
              href: `/rezerwacja/podsumowanie`,
              label: "Podsumowanie",
              state: location.state,
              disabled: true,
            },
          ]}
        />
        <Header>Krok 2 - wybierz samochód</Header>
        <Cars
          rentalState={location.state}
          onChoose={(car, segment) => {
            const state = {
              ...location.state,
              car,
              segment,
            };
            navigate("/rezerwacja/ubezpieczenie", {
              state,
            });
          }}
        />
      </Container>
      <Footer />
    </Layout>
  );
};

export default ReservationCarPage;
